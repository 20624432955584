p,
ol,
ul,
li,
textarea,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
