// Palette colors
$sae-white: #FFF;
$sae-grey: #B5B5B5;

$sae-blue-light-color: #EDF2F8;
$sae-blue-lighter-color: #F7F9FC;
$sae-blue-darker-color: #132537;

$sae-background-color: #f7f9fc;

$sae-primary-color: #007dab;

$sae-text-color: #434349;
$sae-text-light-color: #5D6671;

$sae-error-color: #CF0A2C;

$sae-service-color: #262626;
$sae-empty-color: #929090;
$sae-ahead-color: #2369B3;
$sae-delay-color: #c1292e;
$sae-ontime-color: #009b72;

// Shadow
$sae-blurred-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.06);
$sae-item-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
$sae-item-shadow-light: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
$sae-popup-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
0 6px 16px rgba(0, 0, 0, 0.08),
0 9px 28px 8px rgba(0, 0, 0, 0.05);
$sae-popup-shadow-hover: 0 3px 6px -4px rgba(0, 0, 0, 0.16),
0 6px 16px rgba(0, 0, 0, 0.12),
0 9px 28px 8px rgba(0, 0, 0, 0.09);

// Border
$sae-border-color: #E3EBF6;
$sae-border: 1px solid $sae-border-color;

// Navbar
$sae-navbar-height: 60px;
$sae-subheader-height: 40px;

// Footer
$sae-footer-height: 50px;

// Font family
$sae-font-family-primary: 'Roboto';

// Font weight
$sae-font-weight-regular: 400;
$sae-font-weight-medium: 500;
$sae-font-weight-bold: 700;

// Font size
$sae-font-x-small: 11px;
$sae-font-smaller: 13px;
$sae-font-small: 14px;
$sae-font-medium: 16px;
$sae-font-large: 18px;

// Pin
$pin-height: 28px;

$alerts-sidebar-width: 370px;