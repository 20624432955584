.sae-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
}
