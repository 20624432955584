$section-padding: 30px;
$section-padding-small: 15px;

.sae-section {
    background-color: $sae-white;
    border-radius: 5px;
    box-shadow: $sae-blurred-shadow;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    &.-no-header {
        .section-body {
            padding-top: $section-padding;
        }
    }

    &.-small {
        .section-header {
            padding: $section-padding-small $section-padding-small 0 $section-padding-small;
        }

        .section-body {
            padding: 0 $section-padding-small 0 $section-padding-small;
        }

        &.-no-header {
            .section-body {
                padding-top: $section-padding-small;
            }
        }

        &.-no-footer {
            .section-body {
                padding-bottom: $section-padding-small;
            }
        }
    }

    &.-rounded-top-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding-bottom: 20px;
    }

    .section-body {
        display: flex;
        flex-direction: column;
    }
}
