.color-class(@name, @prop, @map) {
    each(@map, {
            .@{name}-@{key} {
                @{prop}: ~"@{value}" !important;
            }

            .focus\:@{name}-@{key}:focus {
                @{prop}: ~"@{value}" !important;
            }

            .hover\:@{name}-@{key}:hover {
                @{prop}: ~"@{value}" !important;
            }

            .active\:@{name}-@{key}:active {
                @{prop}: ~"@{value}" !important;
            }
        }

    );
}

.style-class(@prop, @map, @states: false) {
    each(@map, {
            .@{key} {
                @{prop}: ~"@{value}" !important;
            }

            .focus\:@{key}:focus when (@states) {
                @{prop}: ~"@{value}" !important;
            }

            .hover\:@{key}:hover when (@states) {
                @{prop}: ~"@{value}" !important;
            }

            .active\:@{key}:active when (@states) {
                @{prop}: ~"@{value}" !important;
            }
        }

    );
}

.border-class(@scales, @directions) {
    each(@scales, .(@i) {
            each(@directions, .(@prop, @dir) {
                    .border-@{dir}-@{i} {
                        each(@prop, .(@prop-name) {
                                @{prop-name}-width: ~"@{i}px" !important;
                                @{prop-name}-style: if((@i=0), none, solid);
                            }

                        )
                    }
                }

            );

            .border-@{i} {
                border-width:~"@{i}px" !important;
                border-style: if((@i=0), none, solid);
            }
        }

    )
}