// Font size
@font-size-props: {
    text-xs: .75rem;
    text-sm: .875rem;
    text-base: 1rem;
    text-lg: 1.125rem;
    text-xl: 1.25rem;
    text-2xl: 1.5rem;
    text-3xl: 1.75rem;
    text-4xl: 2rem;
    text-5xl: 2.5rem;
    text-6xl: 3rem;
    text-7xl: 4rem;
    text-8xl: 6rem;
}

.style-class(font-size, @font-size-props, true);

// Box Shadow
@shadows: {
    shadow-none: "none";
    shadow-1: "0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08)";
    shadow-2: "0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)";
    shadow-3: "0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1)";
    shadow-4: "0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)";
    shadow-5: "0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2)";
    shadow-6: "0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18)";
    shadow-7: "0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15)";
    shadow-8: "0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)";
}

.style-class(box-shadow, @shadows, true);

// Border
@border-scales: 0, 1, 2, 3;

@border-directions: {
    top: border-top;
    right: border-right;
    left: border-left;
    bottom: border-bottom;
    x: border-left, border-right;
    y: border-top, border-bottom;
}

@border-radius-props: {
    border-noround: 0;
    border-round: 6px;
    border-circle: 50%
}

.border-class(@border-scales, @border-directions);
.style-class(border-radius, @border-radius-props, true);

// Cursor
.cursor-pointer {
    cursor: pointer !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.font-light {
    font-weight: 300 !important;
}

.font-normal {
    font-weight: 400 !important;
}

.font-medium {
    font-weight: 500 !important;
}

.font-semibold {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}

.vertical-align-middle {
    vertical-align: middle !important;
}

.flex-1 {
    flex: 1 1 0% !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.row-gap-2 {
    row-gap: 0.5rem !important;
}

// FIXME: antd-css-utilities: add !important
.justify-start {
    justify-content: start !important;
}

.inline-flex {
    display: inline-flex !important;
}