@import "../../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "./mixins.less";
@import "./colors.less";
@import "./utils.less";

@primary-color: #007dab;
@body-background: #f7f9fc;
@modal-header-bg: #132537;
@modal-heading-color: #ffffff;
@modal-close-color: #ffffff;
@control-border-radius: 6px;
@border-radius-base: 6px;
@modal-body-padding: 15px;
@modal-header-padding: 15px;
@modal-header-close-size: 53px;
@tooltip-arrow-width: 6px * sqrt(2);
@badge-font-weight: 600;
@form-item-label-colon-margin-left: 0;
@table-header-sort-active-bg: #f5f5f5;

@sae-navbar-height: 60px;
@sae-subheader-height: 40px;

// Tooltip
.ant-tooltip {
    font-size: 12px;

    &-inner {
        padding: 4px 6px;
        min-height: 28px;
    }
}

// Form
.ant-form-item-label > label {
    font-weight: 500;
}

.ant-form-inline {
    .ant-form-item {
        flex-direction: column;

        &:last-child {
            margin-right: 0;
        }
    }

    .ant-form-item-label {
        padding: 0 0 8px;
        text-align: left;

        & > label {
            margin: 0;
            height: auto;
        }
    }
}

// Button
.ant-btn > i.fa + span,
.ant-btn > i.fa-solid + span {
    margin-left: 8px;
}

.ant-tag > i.fa + span,
.ant-tag > i.fa-solid + span {
    margin-left: 7px;
}

// Checkbox
.ant-checkbox-group.vertical-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px;
    }
}

// Table
.ant-table-wrapper.sticky-header {
    th {
        z-index: 1;
        position: sticky;
        top: calc(@sae-navbar-height + @sae-subheader-height);
        box-shadow: inset 0 -.5px 0 #dadee3;
    }
}

// Space
.ant-space.ant-space-horizontal {
    &.full-width {
        .ant-space-item {
            flex: 1;

            & > * {
                width: 100%;
            }
        }
    }
}

// Modal
.ant-modal-close:focus,
.ant-modal-close:hover {
    color: #ffffff;
}

.cdk-overlay-container {
    z-index: 1000000;
}

// FIXME: to be removed when color-picker is available 
.ant-input[type="color"] {
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 32px;
    background-color: transparent;
    border: 1px solid @input-border-color;
    border-radius: @border-radius-base;
    cursor: pointer;

    &::-webkit-color-swatch-wrapper {
        border-radius: @border-radius-base;
        padding: 0;
    }

    &::-webkit-color-swatch {
        border: none;
        border-radius: @border-radius-base;
    }

    &::-moz-color-swatch {
        border-radius: @border-radius-base;
        border: none;
    }

    &:disabled {
        filter: grayscale(100%);
    }
}