.sae-main-container {
    background-color: $sae-background-color;
    padding-bottom: 30px;
    min-height: calc(100vh - #{$sae-navbar-height} - #{$sae-footer-height});
    display: flex;
    flex-direction: column;

    &.-with-subheader {
        padding-top: $sae-subheader-height;
        min-height: calc(100vh - #{$sae-navbar-height} - #{$sae-subheader-height} - #{$sae-footer-height});
    }
}
