$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1580px
) !default;

@import './bootstrap-base';
@import 'node_modules/bootstrap/scss/bootstrap-grid';

// Colors
$primary: $sae-primary-color;

// Reboot
$body-color: $sae-text-color;
$body-bg: $sae-background-color;
$font-family-base: $sae-font-family-primary;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;
$input-btn-focus-box-shadow: none;
@import 'node_modules/bootstrap/scss/bootstrap-reboot';

@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/transitions';

// Tooltip
.tooltip-inner {
    font-size: $sae-font-x-small;
}

@import 'node_modules/bootstrap/scss/tooltip';

$popover-body-color: $sae-text-color;
@import 'node_modules/bootstrap/scss/popover';

// Modal
$modal-content-bg: $sae-blue-darker-color;
$modal-header-padding: 6px 15px;
$modal-inner-padding: 6px 15px;
@import 'node_modules/bootstrap/scss/modal';

// Dropdown
$dropdown-font-size: $sae-font-smaller;
$dropdown-item-padding-x: 1rem;
$dropdown-link-active-bg: $sae-blue-light-color;
$dropdown-link-active-color: $dropdown-link-color;
@import 'node_modules/bootstrap/scss/dropdown';

// Badge
@import 'node_modules/bootstrap/scss/badge';

@import 'node_modules/bootstrap/scss/utilities/text';
@import 'node_modules/bootstrap/scss/utilities/position';
@import 'node_modules/bootstrap/scss/utilities/sizing';
@import 'node_modules/bootstrap/scss/utilities/borders';
@import 'node_modules/bootstrap/scss/utilities/flex';

svg {
    // Remove Workaround for the SVG overflow bug in IE10/11
    overflow: initial !important;
    vertical-align: initial !important;
}
  