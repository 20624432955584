.sae-close-button {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: $sae-text-color;
    font-size: 20px;
    font-weight: $sae-font-weight-bold;
    line-height: 1;
    padding: 0;

    &.-light {
        color: $sae-white;
    }
}
