@import 'node_modules/ngx-toastr/toastr';

.toast-top-center {
    top: 12px;
}

.ngx-toastr {
    border-radius: 6px !important;
    box-shadow: $sae-popup-shadow !important;
    transition: box-shadow ease-in-out .15s;

    &:hover {
        box-shadow: $sae-popup-shadow-hover !important;
    }
}

.alerts-sidebar-light-toast {
    $toast-padding: 100px;

    margin-right: calc(#{$toast-padding} / 2) !important;
    margin-left: calc(#{$toast-padding} / 2) !important;
    margin-top: calc(#{$sae-navbar-height} + 20px) !important;
    width: calc(#{$alerts-sidebar-width} - #{$toast-padding}) !important;
}
