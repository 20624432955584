@media print {
    @page {
        size: auto;
        margin: 0;
    }

    html,
    body {
        height: 100%;
    }

    body {
        background: url("/assets/img/mobibus/background_export.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding: 0 !important;
    }

    body * {
        visibility: hidden;
    }

    .modal-open {
        background-color: white;
    }

    #printSection,
    #printSection * {
        visibility: visible;
        background-color: transparent;

        .header,
        .footer {
            position: fixed;
            height: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        .header {
            top: 120px;
        }

        .footer {
            bottom: 100px
        }

        sae-alert-info .fa-circle-info,
        sae-alert-info .escalated-info {
            display: none;
        }

        .viewers,
        .export,
        .resolution-user div {
            display: none;
        }

        .resolution-user:after {
            content: "—";
        }

        .map-control {
            display: none !important;
        }
    }

    #printSection {
        position: absolute;
        left: 70px;
        top: 200px;
    }
}