.error-message {
    color: $sae-error-color;
    font-size: $sae-font-x-small;
    font-weight: $sae-font-weight-regular;
    line-height: $sae-font-x-small;
}

.warning-text {
    color: #FA6401;
}

.vertical-align-middle {
    vertical-align: middle;
}
