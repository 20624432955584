// Utilities
@import 'utilities/variables';
@import 'utilities/animation';
@import 'utilities/fonts';
@import 'utilities/exports';

// Tools
@import 'tools/reset';
@import 'tools/toastr';
@import 'tools/bootstrap';
@import 'tools/font-awesome';

// Base
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import 'base/base';

// Atoms
@import 'atoms/buttons';

// Molecules
@import 'molecules/header';

// Organisms
@import 'organisms/section';
@import 'organisms/container';

.sae-metric-counter {
    display: flex;
    align-items: center;
    min-width: 175px;
    background-color: rgba(2, 124, 183, 0.06);
    padding: 15px 20px;
    border-radius: 4px;
    border-left: 5px solid #027cb7;

    &:not(:last-child) {
        margin-right: 30px;
    }
}