.sae-ld {
    $size: 5em;
    $thickness: .5em;

    animation: ld-spin 1s infinite linear;
    transition: all .3s;
    transition-timing-function: ease-in;
    width: $size;
    height: $size;
    display: inline-block;

    &::after {
        content: " ";
        width: $size * 2;
        height: $size * 2;
        box-sizing: border-box;
        transform-origin: 0 0;
        transform: translateZ(0) scale(0.5);
        backface-visibility: hidden;
        border-radius: 50%;
        border: $thickness solid currentColor;
        border-left-color: transparent;
        display: block;
    }
}

@keyframes ld-spin {
    0% {
        animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
        transform: rotate(0);
    }
    
    100% {
        transform: rotate(360deg);
    }
}

.sae-clipboard-button {
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    
    .fa-copy, .fa-check-circle {
        margin-left: 4px;
        max-width: 14px;
        font-size: 14px;
        line-height: inherit;
        vertical-align: middle;
        transition: max-width 250ms ease-in-out, opacity 150ms ease;
        opacity: 1;
    }

    &:not(:hover) {
        .fa-copy, .fa-check-circle {
            margin-left: 0;
            visibility: none;
            opacity: 0;
            max-width: 0;
        }
    }
}
